<template>
  <div class="logo">
    <img src="@/assets/logo.png" alt="logo" />
  </div>
  <h1>{{ msg }}</h1>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.logo {
  color: #fff;
  letter-spacing: -0.075vw;
  margin: 1vw 0 0 1vw;
}
.logo img {
  height: 3vw;
}
div h1 {
  font-family: "Roboto Bold";
  font-size: 2vw;
  color: #fff;
  text-align: center;
  margin-top: 1vw;
  letter-spacing: -0.075vw;
}
</style>
