<template>
  <FaceLayout back="/id-check/face" :msg="$t('face_check.face_scan_success')">
    <div class="canvas-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="207"
        height="147"
        fill="none"
        viewBox="0 0 207 147"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="25"
          d="M13 73.5 73.338 134 194 13"
        />
      </svg>
    </div>
  </FaceLayout>
</template>

<script>
import FaceLayout from "./FaceLayout.vue";

export default {
  components: {
    FaceLayout,
  },
  name: "FaceRecognitionSuccess",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.$router.push("/id-check/confirmation");
    }, 3000);
  },
};
</script>

<style scoped src="@/assets/css/idcheck/face/style.css"></style>
<style scoped>
.canvas-container {
  background: rgba(15, 255, 86, 0.5);
}
</style>
