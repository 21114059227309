<template>
  <FaceLayout back="/id-check/face" :msg="$t('face_check.face_scan_failed')">
    <div class="canvas-container">Bitte erneut versuchen</div>
  </FaceLayout>
</template>

<script>
import FaceLayout from "./FaceLayout.vue";

export default {
  components: {
    FaceLayout,
  },
  name: "FaceRecognitionFailed",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      this.$router.push("/id-check/face");
    }, 3000);
  },
};
</script>
<style scoped src="@/assets/css/idcheck/face/style.css"></style>
<style scoped>
.canvas-container {
  background: rgba(255, 25, 25, 0.5);
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
}
</style>
