<template>
  <div class="background">
    <!-- <div class="background-overlay">
      <div class="white-overlay"></div>
    </div> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "DE");
    }
  },
};
</script>

<style src="@/assets/css/style.css"></style>
<style scoped>
.background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 40vw;
  z-index: -1;
}
</style>
