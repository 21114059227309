<template>
  <base-keyboard-component
    @addToInput="addToInputHandler"
    @removeLastChar="removeLastCharHander"
    @submit="submit"
    :keyLayout="keyboardLayout"
    :showSubmit="true"
    keyboardClass="small-keyboard"
  ></base-keyboard-component>
</template>

<script>
import { mapGetters } from "vuex";
import BaseKeyboardComponent from "./BaseKeyboardComponent.vue";

export default {
  components: { BaseKeyboardComponent },
  name: "SmallKeyboardComponent",
  data() {
    return {
      layout: "letters",
    };
  },
  mounted() {
    if (this.keyboard.value === this.$i18n.t("id_check.not_found")) {
      this.$store.commit("setKeyboardValue", "");
    }
  },
  computed: {
    ...mapGetters(["keyboard"]),
    keyboardLayout() {
      if (this.keyboard.inputKey === "house_number") {
        return this.numberLayout;
      } else {
        return this.lettersLayout;
      }
    },
    lettersLayout() {
      return {
        letters: [
          [
            { label: "Q" },
            { label: "W" },
            { label: "E" },
            { label: "R" },
            { label: "T" },
            { label: "Z" },
            { label: "U" },
            { label: "I" },
            { label: "O" },
            { label: "<", action: "remove", classes: "double-col" },
          ],
          [
            { label: "A" },
            { label: "S" },
            { label: "D" },
            { label: "F" },
            { label: "G" },
            { label: "H" },
            { label: "J" },
            { label: "K" },
            { label: "L" },
            { label: "_", action: "remove", classes: "double-col" },
          ],
          [
            { label: "Y" },
            { label: "X" },
            { label: "C" },
            { label: "V" },
            { label: "B" },
            { label: "N" },
            { label: "M" },
            { label: "P" },
            { label: "Ü" },
            { label: "Shift", action: "shift", classes: "double-col" },
          ],
          [
            { label: "123", action: "toggle" },
            { label: "0" },
            { label: "1" },
            { label: "2" },
            { label: "3" },
            { label: "4" },
            { label: "@" },
            { label: "Ö" },
            { label: "Ä" },
          ],
        ],
      };
    },
    numberLayout() {
      return {
        letters: [
          [{ label: "1" }, { label: "2" }, { label: "3" }],
          [{ label: "4" }, { label: "5" }, { label: "6" }],
          [{ label: "7" }, { label: "8" }, { label: "9" }],
          [{ label: "0" }, { label: "<", action: "remove", classes: "double-col" }],
        ],
      };
    },
  },
  methods: {
    toggleLayout() {
      this.layout = this.layout === "letters" ? "symbols" : "letters";
    },
    addToInputHandler(char) {
      let initialValue = this.keyboard.value || "";

      initialValue += char;

      this.$store.commit("setKeyboardValue", initialValue);
    },
    removeLastCharHander() {
      let initialValue = this.keyboard.value || "";

      initialValue = initialValue.slice(0, -1);

      this.$store.commit("setKeyboardValue", initialValue);
    },
    submit() {
      const inputKey = this.keyboard.inputKey;

      this.$store.commit("setAdressCheckData", {
        key: inputKey,
        value: this.keyboard.value,
      });

      this.$store.commit("setKeyboardInputKey", "");
      this.$store.commit("setKeyboardValue", 0);

      this.$store.commit("setKeyboard", false);
    },
  },
};
</script>

<style scoped></style>
