<template>
  <div>
    <header-component :msg="$t('id-check-confirmation.check_data')"></header-component>
    <div class="content">
      <data-field-component :data="idCheckData"></data-field-component>
    </div>
    <div class="bottom">
      <div class="btn" @click="confirm()">{{ $t("submit") }}</div>
    </div>
    <progress-component level="2"></progress-component>
    <language-component back="/id-check/face"></language-component>
  </div>
</template>

<script>
import ProgressComponent from "../../components/ProgressComponent.vue";
import LanguageComponent from "../../components/LanguageComponent.vue";
import HeaderComponent from "../../components/HeaderComponent.vue";
import DataFieldComponent from "../../components/DataFieldComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "CheckDataPage",
  components: {
    ProgressComponent,
    LanguageComponent,
    HeaderComponent,
    DataFieldComponent,
  },
  computed: {
    ...mapGetters(["idCheckData"]),
  },
  methods: {
    confirm() {
      this.$router.push("/id-check/address/confirmation");
    },
  },
};
</script>
<style scoped>
.content {
  display: flex;
  justify-content: center;
  gap: 3vw;
}
.bottom {
  margin-top: 1.5vw;
}
.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.75vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  margin-left: 1vw;
  width: 13vw;
  height: 3vw;
  border: none;
  margin: auto;
  cursor: pointer;
  text-align: center;
  line-height: 3vw;
  transition: transform 0.2s;
}
.btn:hover {
  transform: scale(1.05);
}
.btn:focus {
  outline: none;
}
</style>
