<template>
  <div>
    <header-component :msg="msg"></header-component>

    <div class="content">
      <slot></slot>
    </div>

    <progress-component level="2"></progress-component>
    <language-component :back="back"></language-component>
  </div>
</template>

<script>
import ProgressComponent from "../../../components/ProgressComponent.vue";
import LanguageComponent from "../../../components/LanguageComponent.vue";
import HeaderComponent from "../../../components/HeaderComponent.vue";

export default {
  name: "FaceLayout",
  props: {
    back: String,
    msg: String,
  },
  components: {
    ProgressComponent,
    LanguageComponent,
    HeaderComponent,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped src="@/assets/css/idcheck/face/style.css"></style>
