<template>
  <div class="code-input">
    <input type="text" v-model="inputValue" maxlength="1" readonly />
  </div>
</template>

<script>
export default {
  name: "CodeInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: this.value || "",
    };
  },
  watch: {
    inputValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.inputValue = newValue;
    },
  },
};
</script>

<style scoped>
.code-input input {
  width: 4vw;
  height: 4vw;
  font-size: 2vw;
  text-align: center;
  border: 0.25vw solid #fff;
  background: #c6c6c6;
  margin: 0 0.5vw;
  color: #fff;
  border-radius: 1.5vw;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  font-family: "Roboto Bold";
}

.code-input input:focus {
  outline: none;
}
</style>
