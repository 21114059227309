<template>
  <div>
    <header-component :msg="$t('register.title')"></header-component>
    <div class="content">
      <div class="partners" ref="partners">
        <div
          class="partner"
          v-for="partner in visiblePartners"
          :key="partner.id"
          @click="toggleSelectedPartner(partner)"
          :class="{ 'partner-selected': isPartnerSelected(partner.name) }"
        >
          <div class="partner-box">
            <img :src="partner.image" :alt="partner.name" />
          </div>
        </div>
      </div>
      <div class="overflow-dots">
        <span
          v-for="(n, index) in totalDots"
          :key="n"
          class="dot"
          :style="{ backgroundColor: index === activeDot ? '#68AEF9' : '#D9D9D9' }"
          @click="scrollToPartner(index)"
        ></span>
      </div>
      <div class="btn" @click="submit()">Abschließen</div>
    </div>
    <language-component :back="backPage"></language-component>
  </div>
</template>

<script>
import LanguageComponent from "../components/LanguageComponent.vue";
import HeaderComponent from "../components/HeaderComponent.vue";
import { mapMutations, mapGetters } from "vuex";

export default {
  components: { LanguageComponent, HeaderComponent },

  name: "RegisterPage",
  data() {
    return {
      currentIndex: 0,
      partners: [
        {
          id: 1,
          image: require("@/assets/img/partner/interwetten.png"),
          name: "Interwetten",
          type: "Sportwetten",
        },
        {
          id: 2,
          image: require("@/assets/img/partner/betano.png"),
          name: "Betano",
          type: "Sportwetten",
        },
        {
          id: 3,
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          id: 4,
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
        {
          id: 5,
          image: require("@/assets/img/partner/interwetten.png"),
          name: "Interwetten",
          type: "Sportwetten",
        },
        {
          id: 6,
          image: require("@/assets/img/partner/betano.png"),
          name: "Betano",
          type: "Sportwetten",
        },
        {
          id: 7,
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          id: 8,
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
        {
          id: 9,
          image: require("@/assets/img/partner/interwetten.png"),
          name: "Interwetten",
          type: "Sportwetten",
        },
        {
          id: 10,
          image: require("@/assets/img/partner/betano.png"),
          name: "Betano",
          type: "Sportwetten",
        },
        {
          id: 11,
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          id: 12,
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
        {
          id: 11,
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          id: 12,
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
        {
          id: 11,
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          id: 12,
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isPartnerSelected"]),
    backPage() {
      if (this.$store.getters.get_auth_page === "register") {
        return "/terms-and-conditions";
      }

      return "/phone";
    },
    visiblePartners() {
      return this.partners;
    },
    totalDots() {
      return Math.ceil(this.partners.length / 8);
    },
    activeDot() {
      return this.currentIndex;
    },
  },
  methods: {
    ...mapMutations(["toggleSelectedPartner"]),
    scrollToPartner(index) {
      if (index < 0 || index >= this.totalDots) return;

      this.currentIndex = index;
      const partnersElement = this.$refs.partners;
      const scrollAmount = (partnersElement.scrollWidth / this.totalDots) * index;
      partnersElement.scrollTo({ left: scrollAmount, behavior: "smooth" });
    },
    handleScroll() {
      const partnersElement = this.$refs.partners;
      const totalWidth = partnersElement.scrollWidth - partnersElement.clientWidth;
      const scrollFraction = partnersElement.scrollLeft / totalWidth;

      this.currentIndex = Math.round(scrollFraction * (this.totalDots - 1));
    },
    submit() {
      this.$router.push("/register-done");
    },
  },
  mounted() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    const partnersElement = this.$refs.partners;
    if (partnersElement) {
      partnersElement.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

.partners {
  display: flex;
  justify-content: center;
  gap: 1vw;
  height: 20vw;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 3vw;
}

.partner {
  width: 13vw;
  height: 9vw;
  background: rgba(217, 217, 217, 0.3);
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.partner-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.partner-box img {
  max-width: 7.5vw;
  max-height: 7.5vw;
  object-fit: contain;
  z-index: -1;
}

.overflow-dots {
  display: flex;
  justify-content: center;
  margin-top: 1vw;
}

.dot {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  margin: 0 0.25vw;
  box-shadow: 0 5px 5px 0 #44444425;
  cursor: pointer;
}

.partner-selected {
  background: #68aef9;
  z-index: 1;
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  width: 13vw;
  height: 3vw;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 3vw;
  box-shadow: 0 5px 5px 0 rgba(45, 45, 45, 0.15);
  margin: auto;
  margin-top: 2vw;
  transition: transform 0.2s;
}

.btn:hover {
  transform: scale(1.05);
}

.btn:focus {
  outline: none;
}
</style>
