<template>
  <FaceLayout back="/id-check" :msg="$t('face_check.title')">
    <div class="canvas-container">
      <video ref="video" autoplay playsinline webkit-playsinline muted hidden></video>
      <canvas ref="canvas"></canvas>
    </div>

    <!-- <div class="canvas-container" v-if="isChecking">
      <div class="loading-spinner"></div>
    </div> -->

    <div v-if="countdown > 0" class="canvas-container-absolute countdown">
      {{ countdown }}
    </div>

    <div v-if="flashActive" class="canvas-container-absolute flash-overlay"></div>

    <div class="btn" @click="makePhoto()">Foto aufnehmen</div>
  </FaceLayout>
</template>

<script>
import FaceLayout from "./FaceLayout.vue";
import { mapActions } from "vuex";

export default {
  components: {
    FaceLayout,
  },
  name: "FaceRecognition",
  data() {
    return {
      countdown: 0,
      flashActive: false,
      constraints: {
        audio: false,
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      },
      isChecking: false,
      isDrawn: false,
      freeze: false,
    };
  },
  methods: {
    ...mapActions(["updatePhotoImage", "compareFaces"]),
    async initializeCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.constraints);
        this.setStream(stream);
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    },
    setStream(stream) {
      const video = this.$refs.video;
      video.srcObject = stream;

      video.onloadedmetadata = () => {
        video.play();

        const canvas = this.$refs.canvas;
        canvas.width = 1920;
        canvas.height = 1080;

        this.draw();
      };
    },
    draw() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      const video = this.$refs.video;

      const drawFrame = () => {
        if (!this.freeze && video && !video.paused && !video.ended) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.save();
          ctx.scale(-1, 1);
          ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);
          ctx.restore();
          requestAnimationFrame(drawFrame);
          this.isDrawn = true;
        }
      };
      drawFrame();
    },
    async makePhoto() {
      if (this.isChecking) return;
      if (!this.isDrawn) return;
      this.isChecking = true;

      this.countdown = 3;

      const countdownInterval = setInterval(() => {
        console.log(this.countdown);
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(countdownInterval);
          this.triggerFlash();
        }
      }, 1000);
    },
    triggerFlash() {
      this.flashActive = true;
      setTimeout(() => {
        this.capturePhoto();
        this.flashActive = false;
      }, 300);
    },
    async capturePhoto() {
      this.freeze = true;

      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      const video = this.$refs.video;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.save();
      ctx.scale(-1, 1);
      ctx.drawImage(video, -canvas.width, 0, canvas.width, canvas.height);
      ctx.restore();

      const base64Image = canvas.toDataURL("image/png");

      this.base64Image = base64Image;

      this.updatePhotoImage(this.base64Image);

      const success = await this.compareFaces();

      console.log("success?", success);

      if (!success) {
        this.isChecking = false;
        this.freeze = false;

        this.$router.push("/id-check/face/failed");
        return;
      }

      setTimeout(() => {
        this.$router.push("/id-check/face/success");
      }, 2000);
    },
  },
  mounted() {
    this.initializeCamera();
  },
};
</script>
<style scoped src="@/assets/css/idcheck/face/style.css"></style>
