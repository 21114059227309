<template>
  <div class="line">
    <div class="full-progress">
      <div class="step" v-for="step in 4" :key="step">
        <div :class="['step-number', { active: progress >= step }]" v-if="step != 4">
          {{ step }}
        </div>

        <div :class="['step-number', { active: progress >= step }]" v-if="step == 4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="32"
            fill="none"
            viewBox="0 0 36 32"
          >
            <path
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="4"
              d="M23.333 30c0-4.124-4.775-7.467-10.666-7.467S2 25.876 2 30m32-16.8-7.111 7.467-3.556-3.734m-10.666 0c-3.928 0-7.111-3.343-7.111-7.466C5.556 5.343 8.739 2 12.666 2c3.928 0 7.112 3.343 7.112 7.467 0 4.123-3.184 7.466-7.111 7.466Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="current-progress" :style="{ width: progressWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressComponent",
  props: {
    level: String,
  },
  data() {
    return {
      progress: this.level,
    };
  },
  computed: {
    progressWidth() {
      return (this.progress / 4) * 100;
    },
  },
};
</script>

<style scoped>
.line {
  width: 30vw;
  height: 0.75vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3vw;
}

.full-progress {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #b3aca8;
  box-shadow: 0 0.5vw 3vw 0 rgba(68, 68, 68, 0.5);
  border-radius: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #68aef9;
  border-radius: 1.5vw;
  z-index: 1;
}

.step {
  position: relative;
  width: 25%;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.step-number {
  width: 3vw;
  height: 3vw;
  background-color: #b3aca8;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
  box-shadow: 0 0.5vw 3vw 0 rgba(68, 68, 68, 0.5);
  transition: background-color 0.3s ease;
  font-family: "Roboto Bold";
  font-size: 1.5vw;
  position: relative;
  left: 3vw;
}

.step-number svg {
  width: 1.5vw;
  height: 1.5vw;
}

.step-number.active {
  background-color: #68aef9;
}
</style>
