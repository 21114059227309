<template>
  <div>
    <header-component :msg="$t('phone.enter_phone_number')"></header-component>
    <div class="content">
      <div class="input-area">
        <div class="phone-background">
          <div class="phone-input">
            <div class="country-selection">
              <div class="custom-dropdown">
                <div class="selected-country" @click="toggleDropdown">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9995 16.8001C11.2995 16.8001 10.5995 16.5301 10.0695 16.0001L3.54953 9.48014C3.25953 9.19014 3.25953 8.71014 3.54953 8.42014C3.83953 8.13014 4.31953 8.13014 4.60953 8.42014L11.1295 14.9401C11.6095 15.4201 12.3895 15.4201 12.8695 14.9401L19.3895 8.42014C19.6795 8.13014 20.1595 8.13014 20.4495 8.42014C20.7395 8.71014 20.7395 9.19014 20.4495 9.48014L13.9295 16.0001C13.3995 16.5301 12.6995 16.8001 11.9995 16.8001Z"
                      fill="#fff"
                    />
                  </svg>
                  <p>{{ getCurrentCountry.dialCode }}</p>
                  <img
                    :src="get_image(getCurrentCountry.code)"
                    class="dropdown-image"
                    loading="lazy"
                    decoding="async"
                  />
                </div>
                <div v-if="dropdownOpen" class="dropdown-options">
                  <div
                    v-for="country in phone_codes"
                    :key="country.id"
                    class="dropdown-option"
                    @click="selectCountry(country)"
                  >
                    <img
                      :src="get_image(country.code)"
                      :alt="country.name"
                      class="dropdown-image"
                      loading="lazy"
                      decoding="async"
                    />
                    <p>{{ country.dialCode }}</p>
                  </div>
                </div>
              </div>
              <div class="line"></div>
            </div>
            <input
              type="text"
              class="phone-number"
              :value="phone"
              disabled
              @input="setPhone($event.target.value)"
            />
          </div>
        </div>
        <button @click="submit()">{{ $t("next") }}</button>
      </div>

      <numpad-component :input="phone" @input="setPhone"></numpad-component>
    </div>
    <language-component :back="backPage"></language-component>
  </div>
</template>
<script>
import NumpadComponent from "../../components/keyboard/NumpadComponent.vue";
import LanguageComponent from "../../components/LanguageComponent.vue";
import HeaderComponent from "../../components/HeaderComponent.vue";
import { mapGetters } from "vuex";
import { countries } from "country-codes-flags-phone-codes";

export default {
  components: { NumpadComponent, LanguageComponent, HeaderComponent },
  data() {
    return {
      selectedCountry: null,
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapGetters(["phone", "countries", "phone_dialCode"]),
    backPage() {
      if (this.$store.getters.getAuthPage === "register") {
        return "/gender";
      }

      return "/";
    },
    get_image() {
      return (flag) =>
        `https://purecatamphetamine.github.io/country-flag-icons/3x2/${flag}.svg`;
    },
    phone_codes() {
      const storeCountries = this.$store.getters.countries;
      const storeCountryCodes = storeCountries.map((country) => country.code);

      const sorted = countries.sort((a, b) => {
        const aInStore = storeCountryCodes.includes(a.code);
        const bInStore = storeCountryCodes.includes(b.code);

        if (aInStore && !bInStore) return -1;
        if (!aInStore && bInStore) return 1;
        return 0;
      });

      return sorted;
    },
    getCurrentCountry() {
      if (!this.selectedCountry) {
        if (this.$store.getters.phone_dialCode) {
          return countries.find(
            (country) => country.dialCode === this.$store.getters.phone_dialCode
          );
        }

        return countries.find((country) => country.code === "DE");
      }
      return countries.find((country) => country.code === this.selectedCountry.code);
    },
  },
  mounted() {},
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.dropdownOpen = false;
    },
    setPhone(value) {
      this.$store.commit("setPhone", value);
    },
    submit() {
      this.$store.commit("setPhoneDialCode", `${this.getCurrentCountry.dialCode}`);
      this.$store.commit("setPhone", `${this.phone}`);
      this.$router.push("/phone/verify");
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.5vw;
}

::-webkit-scrollbar-track {
  background: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
}
.input-area {
  display: flex;
}
.country-selection {
  display: flex;
  align-items: center;
}

.country-selection {
  margin-left: 0.5vw;
}
.country-selection svg {
  height: 1vw;
  width: 1vw;
}

.selected-country {
  display: flex;
  align-items: center;
}

.selected-country p {
  font-family: "Roboto Bold";
  font-size: 1vw;
  color: #fff;
  letter-spacing: -0.075vw;
}

.selected-country .dropdown-image {
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  margin-left: 0.5vw;
  object-fit: cover;
}

.selected-country:hover {
  cursor: pointer;
}

.dropdown-options {
  background: rgba(217, 217, 217, 0.5);
  border-radius: 1.5vw;
}

.dropdown-options {
  background: rgba(217, 217, 217, 0.5);
  border-radius: 1.5vw;
  position: absolute;
  height: 30vh;
  overflow: auto;
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 0.15vw 0.5vw 0.15vw 0.5vw;
  justify-content: center;
}

.dropdown-option p {
  font-family: "Roboto Bold";
  font-size: 1vw;
  color: #fff;
  letter-spacing: -0.075vw;
  margin-left: 0.5vw;
}

.dropdown-option:hover {
  cursor: pointer;
}

.dropdown-option .dropdown-image {
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  object-fit: cover;
}

select {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5vw;
  margin-left: 0.5vw;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: "Roboto Bold";
  font-size: 1.25vw;
}

button {
  background: #68aef9;
  font-family: "Roboto Bold";
  font-size: 1.6vw;
  padding: 1vw 2vw;
  border: none;
  border-radius: 1.5vw;
  cursor: pointer;
  margin-left: 0.5vw;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  letter-spacing: -0.075vw;
  transition: transform 0.2s;
}

button:hover {
  transform: scale(1.1);
}

select:focus {
  outline: none;
}

.line {
  height: 1.5vw;
  width: 0.1vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  background: #fff;
}

.phone-background {
  width: 30vw;
  height: 4vw;
  background: rgba(217, 217, 217, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.5vw;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
}
.phone-input {
  display: flex;
  width: 28vw;
  height: 2.5vw;
  border: 0.15vw solid #fff;
  border-radius: 1.5vw;
  background: transparent;
}
.phone-number {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5vw;
  width: 20vw;
  margin-left: 0.5vw;
  font-family: "Roboto Bold";
  font-size: 1.25vw;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.phone-number::placeholder {
  color: #fff;
}
input {
  color: #fff;
}
.phone-number::selection {
  background: transparent;
}
.phone-number:focus {
  outline: none;
}
</style>
