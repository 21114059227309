<template>
  <div>
    <header-component :msg="$t('email.enter_email')"></header-component>
    <div class="content">
      <div class="input-area">
        <div class="input">
          <input
            type="text"
            class="email"
            :value="email"
            :placeholder="$t('email.input')"
            disabled
          />
        </div>
        <div class="btn" @click="submit()">{{ $t("next") }}</div>
      </div>
      <div class="template-email">
        <div
          v-for="email in templateMails"
          :key="email"
          class="template-input"
          @click="addToInput(email.mail)"
        >
          {{ email.mail }}
        </div>
      </div>
      <div class="keyboard">
        <keyboard-component></keyboard-component>
      </div>
    </div>

    <language-component back="/phone/verify"></language-component>
  </div>
</template>

<script>
import LanguageComponent from "../../components/LanguageComponent.vue";
import HeaderComponent from "../../components/HeaderComponent.vue";
import KeyboardComponent from "../../components/keyboard/KeyboardComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: { LanguageComponent, HeaderComponent, KeyboardComponent },
  name: "EnterEmailPage",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["email", "templateMails"]),
  },
  methods: {
    addToInput(value) {
      const email = (this.email += value.toLowerCase());

      this.$store.commit("setEmail", email);
    },
    submit() {
      if (this.email.includes("@")) {
        this.$router.push("/email/verify");
      }
    },
  },
};
</script>

<style scoped>
.content {
  position: absolute;
  bottom: 3vw;
  left: 32vw;
  transform: translateX(-50%);
}
.template-email {
  display: flex;
  justify-content: flex-start;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.template-input {
  height: 1.5vw;
  background: rgba(217, 217, 217, 0.5);
  color: #fff;
  border-radius: 1.5vw;
  font-family: "Roboto Bold";
  font-size: 0.75vw;
  margin-right: 1vw;
  padding: 0.5vw;
  line-height: 1.5vw;
  text-align: center;
  width: 6.75vw;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  transition: transform 0.2s ease;
}
.template-input:hover,
.template-input:active {
  background: rgba(217, 217, 217, 0.8);
  cursor: pointer;
  transform: scale(1.1);
  animation: hoverEffect 1.5s ease-out;
}

@keyframes hoverEffect {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.keyboard {
  justify-content: flex-start;
}
.input-area {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.input {
  width: 33vw;
  height: 4vw;
  border-radius: 3vw;
  background: rgba(217, 217, 217, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
}
.email {
  background: transparent;
  color: #fff;
  width: 31vw;
  height: 2.5vw;
  font-family: "Roboto Bold";
  font-size: 0.85vw;
  border: 0.1vw solid #fff;
  border-radius: 3vw;
  text-align: center;
  user-select: none;
}
.email:active,
.email:focus {
  outline: none;
}

.email::placeholder {
  color: #fff;
}

.btn {
  background-color: #68aef9;
  color: #fff;
  font-family: "Roboto Bold";
  font-size: 1.25vw;
  letter-spacing: -0.075vw;
  padding: 0.5vw 1vw;
  border-radius: 3vw;
  margin-left: 1vw;
  width: 7vw;
  height: 3vw;
  border: none;
  cursor: pointer;
  line-height: 3vw;
  text-align: center;
  box-shadow: 0 5px 5px 0 rgba(45, 45, 45, 0.15);
}
.btn:focus {
  outline: none;
}
</style>
