<template>
  <back-component :back="back" v-if="showBackButton"></back-component>

  <div class="language-and-help">
    <div class="help">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path
          class="heroicon-ui"
          d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.59 8.59a1 1 0 1 1-1.42-1.42 4 4 0 1 1 5.66 5.66l-2.12 2.12a1 1 0 1 1-1.42-1.42l2.12-2.12A2 2 0 0 0 10.6 8.6zM12 18a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        />
      </svg>
    </div>
    <div class="active-language" @click="toggleLanguageSelection">
      <img :src="activeLanguageImage" alt="Selected Language" />

      <div
        v-if="isLanguageSelectionOpen"
        :class="{ 'language-selection': true, 'fade-out': isFadingOut }"
      >
        <div
          v-for="language in countries"
          :key="language.code"
          class="language-option"
          @click.stop="changeLanguage(language.code)"
        >
          <img :src="language.image" :alt="language.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "./buttons/BackComponent.vue";
import { mapGetters } from "vuex";

export default {
  name: "LanguageComponent",
  props: {
    back: String,
  },
  components: {
    BackComponent,
  },
  data() {
    return {
      activeLanguage: this.$i18n.locale,
      isLanguageSelectionOpen: false,
      isFadingOut: false,
    };
  },
  computed: {
    ...mapGetters(["countries"]),
    showBackButton() {
      return this.$route.path !== "/";
    },
    activeLanguageImage() {
      return this.countries.find((lang) => lang.code === this.activeLanguage)?.image;
    },
  },
  methods: {
    toggleLanguageSelection() {
      if (this.isLanguageSelectionOpen) {
        this.isFadingOut = true;
        setTimeout(() => {
          this.isLanguageSelectionOpen = false;
          this.isFadingOut = false;
        }, 300);
      } else {
        this.isLanguageSelectionOpen = true;
      }
    },
    changeLanguage(code) {
      this.activeLanguage = code;
      this.isLanguageSelectionOpen = false;
      this.$i18n.locale = code;
      localStorage.setItem("locale", code);
    },
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10%);
  }
}

.language-and-help {
  display: flex;
  position: absolute;
  right: -1.5vw;
  bottom: 1.5vw;
}

.help {
  height: 3vw;
  width: 3vw;
  border-radius: 50%;

  background: rgba(217, 217, 217, 0.5);
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
  transition: transform 0.3s ease-in-out;
  margin-right: 0.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help svg {
  fill: #fff;
  height: 2vw;
  width: 2vw;
}
.help:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.active-language {
  cursor: pointer;
}
.active-language img {
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
  transition: transform 0.3s ease-in-out;
}
.active-language img:hover {
  transform: scale(1.1);
}

.language-selection {
  position: absolute;
  bottom: 4vw;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  animation: fadeIn 0.3s ease-in-out;
}

.language-option img {
  height: 2.5vw;
  width: 2.5vw;
  border-radius: 50%;
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
  transition: transform 0.3s ease-in-out;
}

.language-option img:hover {
  transform: scale(1.1);
}

.language-selection.fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}
</style>
