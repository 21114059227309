<template>
  <div class="back" @click="backRedirect()">
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12H20"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 5L20 12L13 19"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "BackComponent",
  props: {
    back: String,
  },
  methods: {
    backRedirect() {
      this.$router.push(this.back);
    },
  },
};
</script>

<style scoped>
.back {
  position: absolute;
  left: 1.5vw;
  bottom: 1.5vw;
  height: 3vw;
  width: 3vw;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.5);
  box-shadow: 0 5px 5px 0 rgba(68, 68, 68, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.back svg {
  transform: rotate(180deg);
  height: 2vw;
  width: 2vw;
}

.back:hover {
  cursor: pointer;
  transform: scale(1.1);
}
</style>
