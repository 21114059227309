import { createStore } from "vuex";
import api from "../api";
import i18n from "../locale";

const store = createStore({
  state() {
    return {
      data: {
        gender: "",
        phone: "",
        phoneDialCode: "",
        authPage: "register",
        sms_code: ["", "", "", ""],
        sms_code_timeout: false,
        email_code: ["", "", "", ""],
        email_code_timeout: false,
        images: {
          scan: {
            frontImage: null,
            backImage: null,
          },
          face: "",
          photo: "",
        },
        currentJob: {
          name: "",
        },
        email: "",
        selectedPartners: {},
      },
      keyboard: {
        show: false,
        value: "",
        inputKey: "",
      },
      partners: [
        {
          image: require("@/assets/img/partner/interwetten.png"),
          name: "Interwetten",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/betano.png"),
          name: "Betano",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/wettarena.png"),
          name: "Wettarena",
          type: "Sportwetten",
        },
        {
          image: require("@/assets/img/partner/bet365.png"),
          name: "Bet365",
          type: "Sportwetten",
        },
      ],
      countries: [
        {
          image: require("@/assets/img/lang/de.webp"),
          name: "Deutsch",
          code: "DE",
        },
        {
          image: require("@/assets/img/lang/en.svg"),
          name: "English",
          code: "EN",
        },
        {
          image: require("@/assets/img/lang/es.jpg"),
          name: "Español",
          code: "ES",
        },
        {
          image: require("@/assets/img/lang/fr.jpg"),
          name: "Français",
          code: "FR",
        },
        {
          image: require("@/assets/img/lang/tr.webp"),
          name: "Türkçe",
          code: "TR",
        },
      ],
      idCheckData: {
        firstname: "",
        birthplace: "",
        id_number: "",

        lastname: "",
        gender: "",
        id_expiry: "",

        birth_name: "",
        nationality: "",
        id_issuer: "",

        birthdate: "",
        document_type: "",
      },
      addressCheckData: {
        zip_code: "",
        city: "",
        street: "",
        house_number: "",
      },
      jobs: [
        {
          name: "employee",
        },
        {
          name: "self_employed",
        },
        {
          name: "student",
        },
        {
          name: "unemployed",
        },
        {
          name: "retired",
        },
        {
          name: "other",
        },
      ],
      templateMails: [
        {
          mail: "@gmail.com",
        },
        {
          mail: "@web.de",
        },
        {
          mail: "@gmx.de",
        },
        {
          mail: "@outlook.com",
        },
        {
          mail: "@hotmail.com",
        },
      ],
    };
  },
  mutations: {
    setSmsCodeTimeout(state, payload) {
      state.data.sms_code_timeout = payload;
    },
    setIdCheckData(state, payload) {
      state.idCheckData = payload;
    },
    setEmailCodeTimeout(state, payload) {
      state.data.email_code_timeout = payload;
    },
    setKeyboardValue(state, payload) {
      console.log("tes", state.keyboard.value);
      state.keyboard.value = payload;
    },
    setKeyboardInputKey(state, payload) {
      state.keyboard.inputKey = payload;
    },
    setKeyboard(state, payload) {
      state.keyboard.show = payload;
    },
    setAdressCheckData(state, payload) {
      state.addressCheckData[payload.key] = payload.value;
    },
    toggleSelectedPartner(state, payload) {
      const partner = state.data.selectedPartners[payload.name];
      if (partner) {
        delete state.data.selectedPartners[payload.name];
      } else {
        state.data.selectedPartners[payload.name] = payload;
      }
    },
    setEmail(state, payload) {
      state.data.email = payload;
    },
    setCurrentJob(state, payload) {
      state.data.currentJob = payload;
    },
    setGender(state, payload) {
      state.data.gender = payload;
    },
    setPhone(state, payload) {
      console.log(payload, typeof payload);
      state.data.phone = payload;
    },
    setPhoneDialCode(state, payload) {
      state.data.phoneDialCode = payload;
    },
    setPhotoImage(state, image) {
      state.data.images.photo = image;
    },
    // from photo scan
    setScanFrontImage(state, image) {
      state.data.images.scan.frontImage = image;
    },
    setScanBackImage(state, image) {
      state.data.images.scan.backImage = image;
    },
    clearData(state) {
      state.data = {
        gender: "",
        phone: "",
        phoneDialCode: "",
        authPage: "register",
        sms_code: ["", "", "", ""],
        sms_code_timeout: false,
        email_code_timeout: false,
        email_code: ["1", "2", "3", "4"],
        images: {
          scan: {
            frontImage: null,
            backImage: null,
          },
          face: "",
          photo: "",
        },
        currentJob: {
          name: "",
        },
        email: "",
        selectedPartners: {},
      };
    },
    setAuthPage(state, payload) {
      state.data.authPage = payload;
    },
  },
  actions: {
    updateIdCheckData({ state }, data) {
      if (data.images?.face?.visible) {
        state.data.images.face =
          "data:image/jpeg;base64," + data.images.face.image;
      }

      if (data.ocr) {
        const address = data.ocr?.address?.split(",") || [
          i18n.global.t("id_check.not_found"),
        ];

        state.addressCheckData.zip_code =
          address?.[0]?.match(/\d+/)?.[0] ||
          i18n.global.t("id_check.not_found");

        state.addressCheckData.city =
          address?.[0]?.replace(/\d+/g, "")?.trim() ||
          i18n.global.t("id_check.not_found");

        const streetAndNumber = address?.[1]?.split(" ") || [];

        state.addressCheckData.street =
          streetAndNumber.slice(0, -1).join(" ").trim() ||
          i18n.global.t("id_check.not_found");
        state.addressCheckData.house_number =
          streetAndNumber.slice(-1)[0] || i18n.global.t("id_check.not_found");

        state.idCheckData.birth_name =
          data.ocr?.birthName || i18n.global.t("id_check.not_found");

        state.idCheckData.birthplace =
          data.ocr?.birthPlace || i18n.global.t("id_check.not_found");
      }

      if (data.mrz) {
        state.idCheckData.firstname =
          data.mrz.firstname || i18n.global.t("id_check.not_found");
        state.idCheckData.lastname =
          data.mrz.lastname || i18n.global.t("id_check.not_found");
        state.idCheckData.birthdate =
          `${data.mrz.birthdate.day}.${data.mrz.birthdate.month}.${data.mrz.birthdate.year}` ||
          i18n.global.t("id_check.not_found");

        const gender = data.mrz.sex || i18n.global.t("id_check.not_found");

        switch (gender) {
          case "F":
            state.idCheckData.gender = i18n.global.t("gender.female");
            break;

          case "M":
            state.idCheckData.gender = i18n.global.t("gender.male");
            break;

          case "D":
            state.idCheckData.gender = i18n.global.t("gender.diverse");
            break;
          default:
            state.idCheckData.gender = i18n.global.t("id_check.not_found");
        }

        state.idCheckData.nationality =
          data.mrz.nationality || i18n.global.t("id_check.not_found");

        const document_type =
          data.mrz.document.code || i18n.global.t("id_check.not_found");

        switch (document_type) {
          case "P":
            state.idCheckData.document_type = i18n.global.t("id_check.codes.P");
            break;

          case "ID":
            state.idCheckData.document_type =
              i18n.global.t("id_check.codes.ID");
            break;

          case "I":
            state.idCheckData.document_type =
              i18n.global.t("id_check.codes.ID");
            break;

          case "AR":
            state.idCheckData.document_type =
              i18n.global.t("id_check.codes.AR");
            break;
          case "AF":
            state.idCheckData.document_type =
              i18n.global.t("id_check.codes.AR");
            break;
          default:
            state.idCheckData.document_type = i18n.global.t(
              "id_check.codes.OTHER"
            );
        }

        state.idCheckData.id_number =
          data.mrz.document.number || i18n.global.t("id_check.not_found");
        state.idCheckData.id_expiry =
          `${data.mrz.document.expiryDate.day}.${data.mrz.document.expiryDate.month}.${data.mrz.document.expiryDate.year}` ||
          i18n.global.t("id_check.not_found");
        state.idCheckData.id_issuer =
          data.mrz.document.issuer || i18n.global.t("id_check.not_found");
      }
    },
    startSmsCodeTimeout({ commit }) {
      setTimeout(() => {
        commit("setSmsCodeTimeout", false);
      }, 30000);
    },
    startEmailCodeTimeout({ commit }) {
      setTimeout(() => {
        commit("setEmailCodeTimeout", false);
      }, 30000);
    },

    sendSmsCode({ state }) {
      const phone = state.data.phoneDialCode + state.data.phone;
      console.log("Sending SMS code to", phone);

      if (process.env.VUE_APP_MODE == "development") {
        return true;
      }

      if (!state.data.phone) {
        console.error("Phone number is not set");
        return;
      }
      console.log(process.env.VUE_APP_MODE);
      console.log(process.env.VUE_APP_API_URL + "/sendOTP");
      api
        .post("/sendOTP", {
          sms: state.data.phone,
        })
        .then((response) => {
          if (!response.status === 200) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async checkSmsCode({ state }, inputCode) {
      if (process.env.VUE_APP_MODE == "development") {
        return true;
      }

      let code = inputCode.join("");
      code = parseInt(code);

      const response = await api
        .post("/verifyOTP", {
          sms: state.data.phone,
          otp: code,
        })
        .catch((error) => {
          console.log(error);
        });

      if (!response || response.status != 200) {
        return false;
      }
      return response.data.isOTPValid || false;
    },
    sendEmailCode({ state }) {
      if (process.env.VUE_APP_MODE == "development") {
        return true;
      }

      if (!state.data.email) {
        console.error("Email is not set");
        return;
      }

      api
        .post("/sendOTP", {
          email: state.data.email,
        })
        .then((response) => {
          if (!response.status === 200) {
            console.log(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async checkEmailCode({ state }, inputCode) {
      if (process.env.VUE_APP_MODE == "development") {
        return true;
      }
      let code = inputCode.join("");
      code = parseInt(code);

      const response = await api
        .post("/verifyOTP", {
          email: state.data.email,
          otp: code,
        })
        .catch((error) => {
          console.log(error);
        });

      if (!response || response.status != 200) {
        return false;
      }

      return response.data.isOTPValid || false;
    },
    async compareFaces({ state }) {
      if (process.env.VUE_APP_MODE == "development") {
        return true;
      }
      // remove data:image/jpeg;base64, from the image
      let cameraImage = state.data.images.photo.split(",")[1];
      let documentImage = state.data.images.face.split(",")[1];

      cameraImage = documentImage;

      console.log("cameraImage", cameraImage);
      console.log("documentImage", documentImage);

      const response = await api
        .post("/compareFaces", {
          cameraImage: cameraImage,
          documentImage: documentImage,
        })
        .catch((error) => {
          console.log(error);
        });

      if (!response || response.status != 200) {
        return false;
      }

      return response.data.Similarity >= 80;
    },
    updateScanFrontImage({ commit }, image) {
      commit("setScanFrontImage", image);
    },
    updateScanBackImage({ commit }, image) {
      commit("setScanBackImage", image);
    },
    updatePhotoImage({ commit }, image) {
      commit("setPhotoImage", image);
    },
    updateGender({ commit }, gender) {
      commit("setGender", gender);
    },
  },
  getters: {
    sms_code_timeout: (state) => state.data.sms_code_timeout,
    email_code_timeout: (state) => state.data.email_code_timeout,
    keyboard: (state) => state.keyboard,
    isPartnerSelected: (state) => (name) => {
      return state.data.selectedPartners[name];
    },
    get_auth_page: (state) => state.data.authPage,
    gender: (state) => state.data.gender,
    phone: (state) => state.data.phone,
    phone_dialCode: (state) => state.data.phoneDialCode,
    sms_code: (state) => state.data.sms_code,
    email_code: (state) => state.data.email_code,
    countries: (state) => state.countries,
    photo_image: (state) => state.data.images.photo,
    scan_images: (state) => state.data.images.scan,
    partners: (state) => state.partners,
    idCheckData: (state) => state.idCheckData,
    addressCheckData: (state) => state.addressCheckData,
    jobs: (state) => state.jobs,
    currentJob: (state) => state.data.currentJob,
    email: (state) => state.data.email,
    templateMails: (state) => state.templateMails,
  },
});

export default store;
