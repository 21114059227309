<template>
  <div class="numpad">
    <div class="numpad-item" @click="$emit('input', input + '1')">1</div>
    <div class="numpad-item" @click="$emit('input', input + '2')">2</div>
    <div class="numpad-item" @click="$emit('input', input + '3')">3</div>
    <div class="numpad-item" @click="$emit('input', input + '4')">4</div>
    <div class="numpad-item" @click="$emit('input', input + '5')">5</div>
    <div class="numpad-item" @click="$emit('input', input + '6')">6</div>
    <div class="numpad-item" @click="$emit('input', input + '7')">7</div>
    <div class="numpad-item" @click="$emit('input', input + '8')">8</div>
    <div class="numpad-item" @click="$emit('input', input + '9')">9</div>
    <div class="numpad-item" @click="$emit('input', '')">X</div>
    <div class="numpad-item" @click="$emit('input', input + '0')">0</div>
    <div class="numpad-item" @click="$emit('input', input.slice(0, -1))">&lt;</div>
  </div>
</template>

<script>
export default {
  name: "NumpadComponent",
  props: {
    input: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.numpad {
  position: absolute;
  bottom: 6.5vh;
  display: flex;
  width: 15vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5vw;
}
.numpad-item {
  background: rgba(217, 217, 217, 0.5);
  height: 4.5vw;
  width: 4.5vw;
  border-radius: 1.5vw;
  font-family: "Roboto Bold";
  font-size: 2.5vw;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px 0 rgba(83, 83, 83, 0.25);
  transition: transform 0.2s ease;
}
.numpad-item:hover,
.numpad-item:active {
  background: rgba(217, 217, 217, 0.8);
  cursor: pointer;
  transform: scale(1.1);
  animation: hoverEffect 1.5s ease-out;
}

@keyframes hoverEffect {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
