import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_API_BAEARER_TOKEN}`,
  },
});

export default api;
